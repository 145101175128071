import { StaticImage } from "gatsby-plugin-image";

const DownloadFileIcon = ({ className, alt }) => {
  return (
    <StaticImage
      src="../../images/icons/file-icon.svg"
      alt={alt}
      placeholder="none"
      layout="fixed"
      loading="eager"
      className={className}
      objectFit="cover"
      aria-hidden={true}
    />
  );
};

export default DownloadFileIcon;
