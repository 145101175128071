import PropTypes from "prop-types";
import { block } from "bem-cn";

import Heading from "components/Heading";
import Paragraph from "components/Paragraph";

import "./style.scss";

const cn = block("main-faq");

const Faq = ({ className, title, children }) => (
  <li key={title} className={cn("item")}>
    <Heading weight="bold" level={4} className={cn("item", { title: true })}>
      {title}
    </Heading>
    <Paragraph>{children}</Paragraph>
  </li>
);

Faq.propTypes = {
  className: PropTypes.object,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Faq;
