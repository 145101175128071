import PropTypes from "prop-types";
import { block } from "bem-cn";

import "./style.scss";

const cn = block("documentation-download-card-block");

const DocumentationDownloadCardBlock = ({ children }) => {
  return <div className={cn("wrap")}>{children}</div>;
};

DocumentationDownloadCardBlock.propTypes = {
  children: PropTypes.node,
};

export default DocumentationDownloadCardBlock;
