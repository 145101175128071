import { createElement } from "react";
import RehypeReact from "rehype-react/index";
import { block } from "bem-cn";

import Heading from "components/Heading";
import Faq from "components/Faq";
import Link from "components/Link";
import Paragraph from "components/Paragraph";
import Image from "components/Image";
import DocumentationDownloadCard from "components/DocumentationDownloadCard";
import DocumentationDownloadCardBlock from "components/DocumentationDownloadCard/_cards-block";

import "./style.scss";

const cn = block("main-custom-components");

export const h2 = ({ children }) => (
  <Heading level={2} weight="bold" className={cn("heading", { h2: true })}>
    {children}
  </Heading>
);

export const h3 = ({ children }) => (
  <Heading level={3} weight="bold" className={cn("heading", { h3: true })}>
    {children}
  </Heading>
);

export const h4 = ({ children }) => (
  <Heading
    level={4}
    weight="bold"
    className={cn("heading", { h4: true, level_6: true })}
  >
    {children}
  </Heading>
);

export const img = ({ src, alt }) => (
  <Image src={src} alt={alt} className={cn("img")} />
);

export const insideLink = ({ children, href }) => (
  <Link href={href} color="primary">
    {children}
  </Link>
);

export const outsideLink = ({ children, href, isNoRel = false }) => (
  <Link
    isOutside
    href={href}
    color="primary"
    target="_blank"
    rel={isNoRel ? "noreferrer noopener" : null}
  >
    {children}
  </Link>
);

export const paragraph = ({ children }) => (
  <Paragraph className={cn("paragraph")}>{children}</Paragraph>
);

export const faqItem = ({ children, title, first }) => (
  <Faq title={title}>{children}</Faq>
);

export const faqBlock = ({ children, title }) => (
  <section
    className={cn("faq", { wrap: true })}
    role="contentinfo"
    aria-label="FAQ"
  >
    <Heading level={2} weight="bold" className={cn("faq", { title: true })}>
      {title}
    </Heading>
    <ul className={cn("faq", { list: true })}>{children}</ul>
  </section>
);

export const ul = ({ children, target = false }) => (
  <ul className={cn("list", { ul: true })}>{children}</ul>
);

export const ol = ({ children }) => (
  <ol className={cn("list", { ol: true })}>{children}</ol>
);

export const li = ({ children }) => (
  <li className={cn("list", { li: true })}>
    <div>{children}</div>
  </li>
);

export const downloadCard = ({ children, href, alt }) => (
  <DocumentationDownloadCard href={href} alt={alt}>
    {children}
  </DocumentationDownloadCard>
);

export const downloadCardBlock = ({ children }) => (
  <DocumentationDownloadCardBlock>{children}</DocumentationDownloadCardBlock>
);

export const table = ({ children }) => (
  <table className={cn("table")} id="docs-table">
    {children}
  </table>
);

export const description = ({ children }) => (
  <span className={cn("description")}>{children}</span>
);

export const customTop = () => <span className={cn("br")} />;

export const renderAst = (toolkit) => {
  return new RehypeReact({
    createElement: createElement,
    components: {
      ...toolkit,
    },
  }).Compiler;
};
