import PropTypes from "prop-types";
import { block } from "bem-cn";

import DownloadFileIcon from "components/Icons/download-file";
import Link from "components/Link/";

import "./style.scss";

const cn = block("documentation-download-card");

const DocumentationDownloadCard = ({ children, href, alt }) => {
  return (
    <figure className={cn("card")}>
      <DownloadFileIcon alt={alt} />
      <figcaption className={cn("text")}>
        <Link
          isOutside
          className={cn("link", { level_7: true })}
          href={href}
          download
        >
          {children}
        </Link>
      </figcaption>
    </figure>
  );
};

DocumentationDownloadCard.propTypes = {
  href: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default DocumentationDownloadCard;
